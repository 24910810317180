import { useEffect, useState, useRef } from "react";
import { Dialog } from "primereact/dialog";
import { TabView, TabPanel } from "primereact/tabview";
import depositTabImg from "../assets/images/depositTab.svg";
import withdrwaTabImg from "../assets/images/withdrwaTab.svg";
import Decimal from "decimal.js";
import { useAccount, useNetwork, useSigner, useSwitchNetwork } from "wagmi";

import stakePoly from "../abi/out/masterChefPolygon.sol/MasterChefPolygon.json";
// import nft from "../abi/out/Nft.sol/Nft.json";
import nft from "../abi/out/ERC721.json";
import Web3 from "web3";
import { Toast } from "primereact/toast";
import { ethers } from "ethers";
import waitingImg from "../assets/images/waiting1.gif";
import successfullImg from "../assets/images/Success.gif";
import Img1 from "../assets/images/tokenimg.png";

interface ManageBtnProps {
  vaultName: string;
  // Img1: string;
  // Img2: string;
  addressNew: string;
  // symbol: string;
  rpc: string;
  denominationDecimal: number;
  denominationAssetAddress: string;
  totalAssets: string;
  totalSupply: string;
  myBalance?: number;
  myBalanceNonDollar?: number;
  mystake?: number;
}

function ManageBtn({
  vaultName,
  // Img1,
  rpc,
  // Img2,
  addressNew,
  // symbol,
  denominationDecimal,
  myBalance,
  mystake,
  myBalanceNonDollar,
  denominationAssetAddress,
  totalAssets,
  totalSupply,
}: ManageBtnProps) {
  const { chain } = useNetwork();
  const [assetAbiVal, setAssetAbi] = useState(null);
  const [visible, setVisible] = useState(false);
  const [maxLimit, setMaxLimit] = useState<any>(null);
  const [depositAmout, setdepositAmout] = useState<any>("0");
  const [isApproved, setisApproved] = useState(false);
  const [walletBalance, setWalletBalance] = useState<any>(null);

  const [userShare, setUserShare] = useState("0");
  const { address, isConnected } = useAccount();
  const { data: signer } = useSigner();
  const addDDD = "0xC7c74fB5aa1b11d2e960B6cf9C057F67c8C602bc";
  const [vaultNumber, setVaultNumber] = useState<number | undefined>();
  const [loading, setLoading] = useState(false);
  const [isDataLoadng, setIsDataLoadng] = useState(false);
  const [isTransactionOnGoing, setIsTransactionOnGoing] = useState(false);
  const [transactionType, setTransactionType] = useState("");
  const [approvalSuccessfullVisible, setapprovalSuccessfullVisible] =
    useState<boolean>(false);
  const [approvalWaitingVisible, setapprovalWaitingVisible] =
    useState<boolean>(false);

  const [openMetamaskVisible, setOpenMetamaskVisible] =
    useState<boolean>(false);
  const [depositWaitingVisible, setdepositWaitingVisible] =
    useState<boolean>(false);
  const [depositSuccessfullVisible, setdepositSuccessfullVisible] =
    useState<boolean>(false);
  const toast = useRef<Toast>(null);
  const [depositAmounts, setDepositAmounts] = useState<number[]>([]);

  const [withdrawAmout, setwithdrawAmout] = useState<number[]>([]);
  useEffect(() => {
    setLoading(true);
    setIsDataLoadng(true);
  }, [address]);

  const powfactor = Math.pow(10, denominationDecimal);
  const showSuccess = (message: string) => {
    toast.current?.show({
      severity: "success",
      summary: "Success",
      detail: message,
      life: 3000,
    });
  };

  const showError = (message: string) => {
    toast.current?.show({
      severity: "error",
      summary: "Error",
      detail: message,
      life: 3000,
    });
  };
  const getContract = (address: string, abi: any, provider: any) => {
    const web3 = new Web3(provider);
    return new web3.eth.Contract(abi, address);
  };
  const estimateGasForApprove = async (
    contract: any,
    spenderAddress: any,
    amount: any
  ) => {
    let gasEstimate;

    if (!gasEstimate) {
      try {
        const tx = {
          to: contract.address,
          data: contract.interface.encodeFunctionData("approve", [
            spenderAddress,
            amount,
          ]),
        };
        const provider = new ethers.providers.JsonRpcProvider(
          "https://polygon-rpc.com"
        );
        gasEstimate = await provider.estimateGas(tx);
      } catch (error) {
        console.error("Provider estimateGas failed:", error);
      }
    }

    // Fallback to a default gas limit
    if (!gasEstimate) {
      gasEstimate = ethers.BigNumber.from(3000000); // Fallback gas limit
      // console.log("Using fallback gas estimate:", gasEstimate.toString());
    }

    return gasEstimate;
  };

  const approveAllowance = async (amount: Number) => {
    try {
      if (!signer) {
        showError("Connect wallet to perform this action");
        return;
      }

      setIsTransactionOnGoing(true);

      const nftContract = new ethers.Contract(
        "0x93f7EA50c7Af2aE2FF3BCB6C4Ec239253A7cC8Ee",
        nft.abi,
        signer
      );

      const tx = await nftContract.approve(
        "0x07ddb38cceA93ec4Edb3430e4316C6b238be3743",
        amount,
        {
          gasLimit: 3000000,
        }
      );

      setapprovalWaitingVisible(true);

      await tx
        .wait()
        .then(() => {
          setapprovalWaitingVisible(false);
          setapprovalSuccessfullVisible(true);
          setisApproved(true);
        })
        .catch((error: any) => {
          console.error("Error approving allowance:", error);
          setapprovalWaitingVisible(false);
          showError("Error approving allowance ");
        });

      showSuccess("Approval successful!");
      return true;
    } catch (error) {
      setapprovalWaitingVisible(false);
      console.error("Error approving allowance:", error);
      showError("Error approving allowance ");
      return false;
    } finally {
      setIsTransactionOnGoing(false);
      setapprovalWaitingVisible(false);
    }
  };

  const handledepositAmoutChange = (event: any) => {
    const val = event.target.value;
    if (!val) {
      showError("Input cannot be empty");
      setDepositAmounts([]);
      return;
    }
    const numbers = val.split(",").flatMap(
      (part: {
        includes: (arg0: string) => any;
        split: (arg0: string) => {
          (): any;
          new (): any;
          map: { (arg0: NumberConstructor): [any, any]; new (): any };
        };
      }) => {
        if (part.includes("-")) {
          const [start, end] = part.split("-").map(Number);
          if (!isNaN(start) && !isNaN(end) && start <= end) {
            return Array.from({ length: end - start + 1 }, (_, i) => start + i);
          } else {
            // showError("Invalid Please try again.");
            showError("Invalid range format");
            return [];
          }
        }
        const num = Number(part);
        // return !isNaN(num) ? [num] : [];
        if (!isNaN(num)) {
          return [num];
        } else {
          showError("Invalid number format");
          return [];
        }
      }
    );
    setDepositAmounts(numbers); // Keep the original input string for the input value
  };

  const showDialog = () => {
    setVisible(true);
  };

  const hideDialog = () => {
    setVisible(false);
  };

  const getTwoDecimal = (num: any) => {
    const roundedNum = Math.floor(num * 100) / 100;
    return roundedNum;
  };
  const handlewithdrawAmoutChange = (event: any) => {
    const val = event.target.value;
    if (!val) {
      showError("Input cannot be empty");
      setDepositAmounts([]);
      return;
    }
    const numbers = val.split(",").flatMap(
      (part: {
        includes: (arg0: string) => any;
        split: (arg0: string) => {
          (): any;
          new (): any;
          map: { (arg0: NumberConstructor): [any, any]; new (): any };
        };
      }) => {
        if (part.includes("-")) {
          const [start, end] = part.split("-").map(Number);
          if (!isNaN(start) && !isNaN(end)) {
            return Array.from({ length: end - start + 1 }, (_, i) => start + i);
          } else {
            showError("Invalid Please try again.");
          }
        }
        const num = Number(part);
        // return !isNaN(num) ? [num] : [];
        if (!isNaN(num)) {
          return [num];
        } else {
          showError("Invalid number format");
          return [];
        }
      }
    );
    setwithdrawAmout(numbers); // Keep the original input string for the input value
  };

  interface CustomTabHeaderProps {
    icon: string;
    title: string;
  }

  const CustomTabHeader: React.FC<CustomTabHeaderProps> = ({ icon, title }) => {
    return (
      <>
        {icon && (
          <img
            src={icon}
            alt="Icon"
            style={{ marginRight: "10px", width: "28px" }}
          />
        )}
        <span>{title}</span>
      </>
    );
  };
  const withdrawAmnt = async () => {
    if (withdrawAmout.length === 0) {
      showError("No values entered for Unstake.");
      return;
    }
    setTransactionType("Withdraw");

    // Convert the array of amounts to Decimal and check for invalid entries
    const bbArray = withdrawAmout.map((amount) => new Decimal(amount));
    if (bbArray.some((bb) => isNaN(Number(bb)) || Number(bb) <= 0)) {
      showError("Invalid Please try again.");
      return;
    }

    // Convert Decimal objects to string or number array as needed by the contract
    const bbArrayStr = bbArray.map((bb) => bb.toString());

    try {
      if (!signer) {
        showError("Connect wallet to perform this action");
        return;
      }

      setIsTransactionOnGoing(true);
      const stakeContract = new ethers.Contract(
        "0x07ddb38cceA93ec4Edb3430e4316C6b238be3743",
        stakePoly.abi,
        signer
      );
      setdepositWaitingVisible(true);

      // Pass the array of amounts to the withdraw function
      const withDraw = await stakeContract.withdraw(bbArrayStr, {
        gasLimit: 3000000,
      });

      await withDraw
        .wait()
        .then(() => {
          setdepositWaitingVisible(false);
          setdepositSuccessfullVisible(true);
          setIsTransactionOnGoing(false);
          showSuccess("Withdrawal successful!");
          // window.location.reload(); // Refresh the page
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        })
        .catch((error: any) => {
          setIsTransactionOnGoing(false);
          setdepositWaitingVisible(false);
          showError("Something went wrong");
          console.error("Error withdrawing:", error);
        });
    } catch (error) {
      setIsTransactionOnGoing(false);
      setdepositWaitingVisible(false);
      console.error("Error withdrawing:", error);
      showError("Error withdrawing");
    } finally {
      setIsTransactionOnGoing(false);
      setdepositWaitingVisible(false);
    }
  };

  const switchNetwork = useSwitchNetwork();
  const networkSwitchHandler = (networkId: number) => {
    (switchNetwork as any).switchNetwork(networkId);
  };
  const depositAmnt = async () => {
    if (depositAmounts.length === 0) {
      showError("No values entered for Stake.");
      return;
    }
    try {
      if (!signer) {
        showError("Connect wallet to perform this action");
        return;
      }

      const approvedAmounts = [];

      for (const amount of depositAmounts) {
        const bb = Number(amount); // Convert to number
        if (isNaN(bb) || bb <= 0) {
          showError("Invalid amount. Please try again.");
          return;
        }

        const approved = await approveAllowance(bb);
        if (!approved) {
          showError("Approval failed. Please try again.");
          return;
        }

        approvedAmounts.push(bb); // Add the numeric amount
      }

      if (approvedAmounts.length > 0) {
        setIsTransactionOnGoing(true);
        const vaultContract = new ethers.Contract(
          "0x07ddb38cceA93ec4Edb3430e4316C6b238be3743",
          stakePoly.abi,
          signer
        );

        const gasEstimates = await Promise.all(
          approvedAmounts.map(async (amount) => {
            try {
              const gasEstimate = await vaultContract.estimateGas.stake(amount);
              return gasEstimate.toNumber(); // Convert BigNumber to number
            } catch (error) {
              return 3000000; // Fallback gas limit
            }
          })
        );

        const tx = await vaultContract.stake(approvedAmounts, {
          gasLimit: Math.max(...gasEstimates),
        });

        setdepositWaitingVisible(true);

        await tx
          .wait()
          .then(() => {
            setdepositWaitingVisible(false);
            setdepositSuccessfullVisible(true);
            showSuccess("Deposit successful!");
            // window.location.reload();
          })
          .catch((error: any) => {
            setdepositWaitingVisible(false);
            console.error("Error depositing:", error);
            showError("Error depositing");
          });
        // window.location.reload();
        setTimeout(() => {
          window.location.reload();
        }, 3000); // Delay the reload by 10 seconds (10000 milliseconds)
      }
    } catch (error) {
      console.error("Error depositing:", error);
      showError("Error depositing");
    } finally {
      setIsTransactionOnGoing(false);
      setdepositWaitingVisible(false);
    }
  };

  return (
    <>
      <Toast ref={toast} />

      <button
        className="mt-05 btn-riv-manage whitespace-nowrap"
        type="button"
        onClick={showDialog}
      >
        Manage
      </button>
      <Dialog
        visible={visible}
        className="custom-dialog"
        onHide={hideDialog}
        modal
        header={`Manage ${vaultName}`}
      >
        <div className="second_section outer_section_detail pos_sticky ">
          <TabView>
            <TabPanel
              header={<CustomTabHeader icon={depositTabImg} title="Stake" />}
            >
              <div className="mt-3">
                <div className="dsp backGrd mb-3 border2 ">
                  <div>
                    <input
                      type="text"
                      placeholder="Token Ids (e.g. 1,2,3 or 1-5)"
                      onChange={handledepositAmoutChange}
                      // value={depositAmout}
                      // Use the state variable to display the input value
                      className="border-2 w-full rounded-lg border-border-color p-2 outline-none text-primary-color"
                    />
                  </div>
                  <div className="header_font_size2 redHatFont">
                    <span>
                      <img
                        src={Img1}
                        alt="btc img"
                        className="btc_asst_img_width"
                      />
                    </span>
                    {/* <span>
                      <img
                        src={Img2}
                        alt="btc img"
                        className="btc_img_width2"
                      />
                    </span> */}
                    {vaultName}
                  </div>
                </div>

                <div>
                  <div className="flexFoot">
                    <button
                      className="btn mt-05 btn-riv-primary whitespace-nowrap"
                      type="button"
                      onClick={depositAmnt}
                      disabled={isTransactionOnGoing}
                    >
                      Stake
                    </button>
                    {/* <button className="btn mt-05 btn-riv-primary whitespace-nowrap" type="button" onClick={depositAmnt}>  Stake</button> */}
                    <button
                      className="btn mt-05 btn-riv-primary-cancel whitespace-nowrap"
                      type="button"
                      onClick={hideDialog}
                    >
                      {" "}
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel
              header={<CustomTabHeader icon={withdrwaTabImg} title="Unstake" />}
            >
              <div className="mt-3 ">
                <div></div>

                <div className="dsp backGrd mb-3 border2 ">
                  <div>
                    <input
                      className="border-2 w-full rounded-lg border-border-color p-2 outline-none text-primary-color"
                      type="text"
                      id="first_name_2"
                      name="first_name_2"
                      placeholder="Token Ids (e.g. 1,2,3 or 1-5)"
                      // value={withdrawAmout.join(",")}
                      onChange={handlewithdrawAmoutChange}
                    />
                  </div>
                  <div className="margStake header_font_size2 redHatFont">
                    <span>
                      <img
                        src={Img1}
                        alt="btc img"
                        className="btc_asst_img_width"
                      />
                    </span>
                    {/* <span>
                      <img
                        src={Img2}
                        alt="btc img"
                        className="btc_img_width2"
                      />
                    </span> */}
                    {vaultName}
                  </div>
                </div>
                <div className="dsp">
                  <div></div>
                  <div className="buy_cake">
                    {" "}
                    <a
                      rel="noreferrer"
                      target="_blank"
                      className="clr_prpl"
                    ></a>
                  </div>
                </div>
                <div className="flexFoot">
                  <button
                    className="btn mt-05 btn-riv-primary whitespace-nowrap"
                    type="button"
                    onClick={withdrawAmnt}
                    disabled={isTransactionOnGoing}
                  >
                    {" "}
                    UnStake
                  </button>
                  <button
                    className="btn mt-05 btn-riv-primary-cancel whitespace-nowrap"
                    type="button"
                    onClick={hideDialog}
                  >
                    {" "}
                    Cancel
                  </button>
                </div>
              </div>
            </TabPanel>
          </TabView>
        </div>
      </Dialog>
      <Dialog
        visible={approvalWaitingVisible}
        className="dialogWdth"
        onHide={() => setapprovalWaitingVisible(false)}
      >
        <div className="text-center">
          <img src={waitingImg} alt="chain" className="gif_wdth" />
          <div className="header_font_size">Waiting for Approval</div>
          <div>Transaction is pending on blockchain.</div>
        </div>
      </Dialog>
      <Dialog
        visible={approvalSuccessfullVisible}
        className="dialogWdth"
        onHide={() => setapprovalSuccessfullVisible(false)}
      >
        <div className="text-center">
          <img src={waitingImg} alt="chain" className="gif_success_wdth" />
          <div className="header_font_size">Approval Successful</div>
          <div>
            Opening Metamask, please confirm transaction in your wallet.
          </div>
        </div>
      </Dialog>
      <Dialog
        visible={openMetamaskVisible}
        className="dialogWdth"
        onHide={() => setOpenMetamaskVisible(false)}
      >
        <div className="text-center">
          <img src={successfullImg} alt="chain" className="gif_success_wdth" />
          <div className="header_font_size">Opening Metamask</div>
          <div>Please confirm transaction in your wallet.</div>
        </div>
      </Dialog>

      <Dialog
        visible={depositWaitingVisible}
        className="dialogWdth"
        onHide={() => setdepositWaitingVisible(false)}
      >
        <div className="text-center">
          <img src={waitingImg} alt="chain" className="gif_wdth" />
          <div className="header_font_size">Waiting for {transactionType}</div>
          <div>Transaction is pending on blockchain.</div>
        </div>
      </Dialog>
      <Dialog
        visible={depositSuccessfullVisible}
        className="dialogWdth"
        onHide={() => setdepositSuccessfullVisible(false)}
      >
        <div className="text-center">
          <img src={successfullImg} alt="chain" className="gif_success_wdth" />
          <div className="header_font_size">{transactionType} Successful</div>
          {transactionType === "Withdraw" ? (
            <></>
          ) : (
            <div>Great! You are invested now.</div>
          )}
        </div>
      </Dialog>
    </>
  );
}

export default ManageBtn;
