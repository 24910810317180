import { ethers } from "ethers";
import { useEffect, useState, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useAccount, useSwitchNetwork, useNetwork } from "wagmi";
import ManageBtn from "../../component/manageBtn";
import ConnectBtn from "../../component/ConnectBtn";
import ClaimButton from "../../component/ClaimButton";
import Web3 from "web3";
import { Toast } from "primereact/toast";
import { Skeleton } from "primereact/skeleton";
// import coins from "../../assets/images/manageImageDegen.png";
import coins from "../../assets/images/fracbg.png";
import stakePoly from "../../abi/out/masterChefPolygon.sol/MasterChefPolygon.json";
import nft from "../../abi/out/Nft.sol/Nft.json";

interface VaultItem {
  _id: string;
  // symbol: string;
  displayName: string;
  // strategyType: string;
  name: string;
  chain?: number;
  // masterChef: string | null;
  vaultAddress: string;
  denominationAssetAddress: string;
  chainRPC: string;
  totalAssets: string;
  totalSupply: string;
  denominationDecimal: number;
  apr: {
    apr: number;
  } | null;
  totalStaked?: string;
  totalStakedDollar?: number;

  mybalance?: number;
  myBalanceDollar?: number;
  earning?: number;
  totalStake?: number;
}

function Manage() {
  const { chain } = useNetwork();
  const [vaults, setVaults] = useState<VaultItem[]>([]);
  const { address, isConnected } = useAccount();
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [diff, setDiff] = useState(0);
  const [walletBalance, setWalletBalance] = useState<any>(0);
  const toast = useRef<Toast>(null);

  const [vaultNumberMyStake, setVaultNumberMyStake] = useState<
    number | undefined
  >();
  const [vaultNumberTotalStaked, setVaultNumberTotalStaked] = useState<
    number | undefined
  >();
  const [vaultNumberBalance, setVaultNumberBalance] = useState<
    number | undefined
  >();

  const getTwoDecimal = (num: any) => {
    const roundedNum = Math.floor(num * 100) / 100;
    return roundedNum.toFixed(2);
  };

  const fnum = (x: any) => {
    if (isNaN(x)) return x;

    if (x < 999) {
      return getTwoDecimal(x);
    }

    if (x < 1000) {
      return getTwoDecimal(x);
    }

    if (x < 10000) {
      return getTwoDecimal(x / 1000) + "K";
    }

    if (x < 100000) {
      return getTwoDecimal(x / 1000) + "K";
    }

    if (x < 1000000) {
      return getTwoDecimal(x / 1000) + "K";
    }

    if (x < 10000000) {
      return getTwoDecimal(x / 1000000) + "M";
    }

    if (x < 1000000000) {
      return getTwoDecimal(x / 1000000) + "M";
    }

    if (x < 1000000000000) {
      return getTwoDecimal(x / 1000000000) + "B";
    }

    return "1T+";
  };

  useEffect(() => {
    const hardcodedVaults: VaultItem[] = [
      {
        _id: "1",
        // symbol: "RIV-01-01-Z",
        displayName: "Cliffton",
        // strategyType: "Strategy 1",
        name: "Cliffton",
        // masterChef: "0xC7c74fB5aa1b11d2e960B6cf9C057F67c8C602bc",
        vaultAddress: "0xVault1Address",
        denominationAssetAddress: "0xDenomination1",
        chainRPC: "https://polygon-rpc.com/",
        totalAssets: "1000000",
        totalSupply: "500000",
        denominationDecimal: 18,
        apr: { apr: 10 },
        totalStaked: " / 235",
      },
      // Add more hardcoded vaults here as needed
    ];
    const fetchVaultData = async () => {
      setIsDataLoading(true);
      const provider = new ethers.providers.JsonRpcProvider(
        "https://polygon-rpc.com"
      );
      const stakeContract = new ethers.Contract(
        "0x07ddb38cceA93ec4Edb3430e4316C6b238be3743",
        stakePoly.abi,
        provider
      );
      const nftContract = new ethers.Contract(
        "0x93f7EA50c7Af2aE2FF3BCB6C4Ec239253A7cC8Ee",
        nft.abi,
        provider
      );
      const yieldearning = await stakeContract.userStakeInfo(address);
      const earning = Number(yieldearning._availableRewards);
      const mybalance = Number(yieldearning._stakedTokenIds.length);
      const myBalanceDollar = mybalance * 1000;
      const totalStake = await nftContract.balanceOf(
        "0x07ddb38cceA93ec4Edb3430e4316C6b238be3743"
      );

      console.log(yieldearning);
      console.log(earning);
      console.log(mybalance);
      console.log("mystake", totalStake.toString());

      // Add the fetched values to hardcodedVaults
      const updatedVaults = hardcodedVaults.map((vault) => ({
        ...vault,
        earning,
        mybalance,
        myBalanceDollar,
        totalStake,
      }));

      setVaults(updatedVaults);
      setIsDataLoading(false);
    };

    fetchVaultData();
  }, [isConnected, address]);

  useEffect(() => {
    if (chain) {
      setDiff(chain.id); // Update the diff state with the current network ID
    }
  }, [chain]);

  const getContract = (address: string, abi: any, provider: any) => {
    const web3 = new Web3(provider);
    return new web3.eth.Contract(abi, address);
  };

  const switchNetwork = useSwitchNetwork();
  const networkSwitchHandler = (networkId: number) => {
    (switchNetwork as any).switchNetwork(networkId);
  };

  const representativeBodyTemplate = (rowData: VaultItem) => {
    return (
      <div className="justify-content-between d-flex align-items-start whitespace-nowrap redHatFont  fnt_wgt_600">
        <div>Cliffton</div>
      </div>
    );
  };

  const representativeBodyTemplateAPR = (rowData: VaultItem) => {
    return (
      <div className="d-flex gap2 flex-column redHatFont  fnt_wgt_600">
        <div>6.38%</div>
      </div>
    );
  };

  const representativeBodyTemplateEarning = (rowData: VaultItem) => {
    return (
      <div className="d-flex gap2 flex-column ">
        <div className="d-flex">
          <span className="redHatFont fnt_wgt_600 flexTwoBtn2">
            {isDataLoading ? (
              <Skeleton width="3rem" className="mb-2"></Skeleton>
            ) : (
              <>${fnum(rowData.earning)}</>
            )}
          </span>
        </div>
      </div>
    );
  };

  const representativeBodyTemplateMasterBalance = (rowData: VaultItem) => {
    return (
      <div className="d-flex flex-column  ">
        <div className="d-flex">
          <span className="redHatFont fnt_wgt_600">
            {isDataLoading ? (
              <Skeleton width="3rem" className="mb-2"></Skeleton>
            ) : (
              <>{fnum(rowData.mybalance)}</>
            )}
          </span>
        </div>
        <div
          className="flex-exchng font_300"
          style={{ whiteSpace: "pre-wrap" }}
        >
          <span>
            {isDataLoading ? (
              <Skeleton width="3rem" className="mb-2"></Skeleton>
            ) : (
              <>(${fnum(rowData.myBalanceDollar)})</>
            )}
          </span>
        </div>
      </div>
    );
  };

  const representativeBodyTemplateTValue = (rowData: VaultItem) => {
    return (
      <div className="d-flex flex-column  ">
        <div className="d-flex">
          <span className="redHatFont fnt_wgt_600">
            {isDataLoading ? (
              <Skeleton width="3rem" className="mb-2"></Skeleton>
            ) : (
              <>
                {Number(rowData.totalStake)}

                {rowData.totalStaked}
              </>
            )}
          </span>
        </div>
      </div>
    );
  };

  return (
    <>
      <Toast ref={toast} />
      <div className="custom-container">
        {isConnected ? (
          <div className="second_section_xRiv outer_section_first_xRiv">
            <div
              className="dsp_cont_first_section"
              style={{ position: "relative" }}
            >
              <div style={{ position: "relative" }}>
                <img
                  height={200}
                  src={coins}
                  alt="rocket img"
                  className="manageImage notMobileDevice"
                  style={{ width: "100%" }}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="second_section_xRiv outer_section_first_xRiv">
            <div
              className="dsp_cont_first_section"
              style={{ position: "relative" }}
            >
              <div style={{ position: "relative" }}>
                <img
                  height={200}
                  src={coins}
                  alt="rocket img"
                  className="manageImage notMobileDevice"
                  style={{ width: "100%" }}
                />
              </div>
            </div>
          </div>
        )}
        <div>
          <h2 className="redHatFont history_txt1 mt-1 ml-105">
            Active Properties
          </h2>
          <DataTable
            scrollable
            tableStyle={{ minWidth: "50rem" }}
            className="p-datatable-striped"
            value={vaults}
          >
            <Column
              headerClassName="im_header txt_gray"
              className="im_dark_lightBG"
              bodyClassName="im_dark_lightText "
              field="displayName"
              header="Property"
              body={representativeBodyTemplate}
              style={{ width: "100px" }}
            />
            <Column
              headerClassName="im_header txt_gray"
              className="im_dark_lightBG"
              bodyClassName="im_dark_lightText "
              field="apr.apr"
              header="APR"
              body={representativeBodyTemplateAPR}
              style={{ minWidth: "10rem" }}
            />

            <Column
              headerClassName="im_header txt_gray"
              className="im_dark_lightBG"
              bodyClassName="im_dark_lightText "
              field="myBalance"
              header="My Balance"
              body={representativeBodyTemplateMasterBalance}
              style={{ minWidth: "10rem" }}
            />

            <Column
              headerClassName="im_header txt_gray"
              className="im_dark_lightBG"
              bodyClassName="im_dark_lightText "
              field="totalStaked"
              header="Total Staked"
              body={representativeBodyTemplateTValue}
              style={{ minWidth: "10rem" }}
            />
            <Column
              headerClassName="im_header txt_gray"
              className="im_dark_lightBG"
              bodyClassName="im_dark_lightText "
              field="myslushEarning"
              header="Earnings"
              body={representativeBodyTemplateEarning}
              style={{ minWidth: "10rem" }}
            />
            {/* button */}
            <Column
              headerClassName="im_header txt_gray "
              className="im_dark_lightBG"
              bodyClassName="im_header txt_gray "
              style={{ width: "120px" }}
              alignFrozen="right"
              body={(rowData: VaultItem) =>
                isConnected ? (
                  diff !== Number(137) ? (
                    <button
                      className="btn btn-riv-primary wdth_100 redHatFont fnt_wgt_600 whitespace-nowrap"
                      onClick={() => {
                        networkSwitchHandler(137);
                      }}
                    >
                      Switch to polygon
                    </button>
                  ) : (
                    <ClaimButton />
                  )
                ) : (
                  <ConnectBtn />
                )
              }
            />
            <Column
              headerClassName="im_header txt_gray"
              className="im_header txt_gray"
              bodyClassName=" im_header txt_gray"
              style={{ width: "120px" }}
              alignFrozen="right"
              frozen
              body={(rowData: VaultItem) =>
                isConnected ? (
                  diff !== Number(137) ? (
                    <button
                      className="btn btn-riv-primary wdth_100 whitespace-nowrap redHatFont fnt_wgt_600"
                      onClick={() => {
                        networkSwitchHandler(137);
                      }}
                    >
                      Switch to polygon
                    </button>
                  ) : (
                    <ManageBtn
                      vaultName={rowData.displayName}
                      // symbol={rowData.symbol}
                      // Img1={`../image/assets/${
                      //   rowData.displayName.toLowerCase().split("-")[0]
                      // }.png`}
                      // Img2={`../image/assets/${
                      //   rowData.displayName.toLowerCase().split("-")[1]
                      // }.png`}
                      addressNew={rowData.vaultAddress}
                      rpc={rowData.chainRPC}
                      denominationDecimal={rowData.denominationDecimal}
                      denominationAssetAddress={
                        rowData.denominationAssetAddress
                      }
                      totalAssets={rowData.totalAssets}
                      totalSupply={rowData.totalSupply}
                      myBalance={rowData.mybalance}
                      // myBalanceNonDollar={rowData.myBalanceNonDollar}
                      // mystake={rowData.earning}
                    />
                  )
                ) : (
                  <ConnectBtn />
                )
              }
            />
          </DataTable>
        </div>
      </div>
    </>
  );
}

export default Manage;
