import React, { useState, useEffect, useRef } from "react";
import { useSigner } from "wagmi";
// import masterChef from "../abi/out/MasterChefNew.sol/MasterChefNew.json";
import stakePoly from "../abi/out/masterChefPolygon.sol/MasterChefPolygon.json";
import { ethers } from "ethers"; // Import ethers.js library
import ConnectBtn from "./ConnectBtn";
import { Toast } from "primereact/toast";

interface PoolToken {
  index: number;
  share: string;
  address: string;
}

interface ManageBtnProps {
  // vaultName: string;
  // Img1: string;
  // Img2: string;
  // addressNew: string;
  // symbol: string;
  // rpc: string;
  // denominationDecimal: number;
}

const ClaimButton: React.FC<ManageBtnProps> = (
  {
    // vaultName,
    // Img1,
    // Img2,
    // addressNew,
    // symbol,
    // rpc,
  }
) => {
  const [visible, setVisible] = useState(false);
  const [poolTokens, setPoolTokens] = useState<PoolToken[]>([]);
  const [vaultNumber, setVaultNumber] = useState<number | undefined>();
  const { data: signer } = useSigner();
  const [isTransactionOnGoing, setIsTransactionOnGoing] = useState(false);
  const toast = useRef<Toast>(null);

  const showDialog = () => {
    setVisible(true);
  };

  const hideDialog = () => {
    setVisible(false);
  };

  const showSuccess = (message: string) => {
    toast.current?.show({
      severity: "success",
      summary: "Success",
      detail: message,
      life: 3000,
    });
  };

  const showError = (message: string) => {
    toast.current?.show({
      severity: "error",
      summary: "Error",
      detail: message,
      life: 3000,
    });
  };

  const withdraw = async () => {
    try {
      if (!signer) {
        console.log("Connect wallet to perform this action");
        return;
      }

      const addDDD = "0x07ddb38cceA93ec4Edb3430e4316C6b238be3743";
      const stakeContract = new ethers.Contract(addDDD, stakePoly.abi, signer);
      const intValue = await stakeContract.claimRewards({
        gasLimit: 300000,
      });
      setIsTransactionOnGoing(true);
      showSuccess("Claim successful!");
    } catch (error) {
      showError("Error claiming");
      console.error("Error claiming:", error);
    } finally {
      setIsTransactionOnGoing(false);
    }
  };

  return (
    <>
      <Toast ref={toast} />
      {signer ? (
        <button
          className="mt-05 btn-riv-manage whitespace-nowrap"
          type="button"
          onClick={withdraw}
        >
          Claim
        </button>
      ) : (
        <ConnectBtn />
      )}
    </>
  );
};

export default ClaimButton;
